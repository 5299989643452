/**
 * @ author Jakob Frömbgen
 * description: language file, language: English
 */
const en = {

  /**
   * structure:
   *
   * <screenName> : {
   *   <element>: <translation>
   *   <buttons>: {
   *     <button_name>: <translation>
   *   }
   * }
   */

  navBar: {
    canteen: "Canteen",
    rooms: "Rooms",
    chess: "Chess",
    btn: {
      login: "Log In",
      logout: "Log Out",
      profile: "Profile",
    }
  },

  profile: {
    language: "Language",
    btn: {
      deleteProfile: "Delete Profile",
      darkTheme: "Dark Theme",
      notifications: "Notifications"
    }
  },

  rooms: {
    note: "If you can't find your room, you can create it here.",
    loading: "Loading rooms...",
    modal: {
      name: "Name",
      title: "Create Room",
      buildings: "Buildings",
      selectBuilding: "Select Building",
      roomNumber: "Room Number",
      namePlaceholder: "Torture Chamber",
      eG: "e.g.: 4.064",
      descriptionPlaceholder: "Torture takes place here",
      description: "Description",
      selectImage: "Select Image",
      btn: {
        save: "Save",
        cancel: "Cancel",
      }
    },
    btn: {
      createRoom: "Create Room",
    },
  },

  room: {
    modal: {
      title: "Join Room",
      btn: {
        close: "close"
      }
    },
    btn: {
      delete: "Delete Room",
      generateQRCode: "Generate QR Code"
    },
    nobody: "None",
    personSg: "Person",
    personPl: "People",
    personGenPl: "People's",
    inTheRoom: "in the room",
  },

  alerts: {
    200: "The request was executed successfully",
    401: "You are not authorized to perform this action",
    403: "You are not authorized to perform this action",
    404: "The requested page was not found",
    500: "An unexpected error occurred on the server",
    503: "The server is currently not available",
  },

  error: {
    ERR_SERVER: "An unexpected error occurred on the server.",
    ERR_INVALID_REQUEST: "Invalid request.",
    ERR_NO_INPUT: "You haven't entered anything.",
    ERR_SAVE_DB_ERROR: "An error occurred while saving.",
    ERR_WRONG_CREDENTIALS: "The entered email or password is incorrect.",
    ERR_INVALID_EMAIL: "Please enter a valid email address.",
    ERR_EMAIL_TOO_LONG: "Your email address can be a maximum of 256 characters long.",
    ERR_PASSWORDS_NOT_IDENTICAL: "Your passwords do not match.",
    ERR_NAME_EXISTS: "This name already exists.",
    ERR_EMAIL_EXISTS: "This email address already exists.",
    ERR_404: "The requested page was not found.",
    ERR_TOKEN_EXPIRED: "The given token has expired.",
    ERR_TOKEN_EMAIL_INVALID: "The entered email does not exist or the given token is invalid.",
    ERR_NO_PASSWORD_CONFIRMATION: "Please enter your current password to make changes.",
    ERR_ACCOUNT_NOT_VERIFIED: "Please confirm your account with the confirmation link sent to you.",
    ERR_IMAGE_UPLOAD: "An error occurred while uploading the image. The file must not exceed 5MB. Allowed file formats: GIF, PNG, and JPEG."
  },
};

export default en;
