import Room from "./../Components/Room"
import {Button, Card, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {doGetJson, doPutJson, doPostFile} from "../etc/scripts/requests";
import counterpart from 'counterpart';
import CustomComponent from "../Components/CustomComponent";

class Rooms extends CustomComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rooms: [],
      showModal: false,
      buildings: [],
      modalData: {
        id: "",
        building: {
          id: "",
        },
        name: "",
        roomNumber: "",
        description: "",
        image: "",
        location: {
          lat: 0,
          lng: 0
        }
      }
    }

    this.alertHandler = this.alertHandler.bind(this);
    this.getData = this.getData.bind(this)
  }

  async componentDidMount() {
    await this.getData("rooms")
  }

  /**
   * Fetches all rooms from the backend
   */
  async getData(path) {
    try {
      let res = await doGetJson(`${this.gateway}/rooms/${path}`)
      this.setState({[path]: res.data})
    } catch (e) {
      this.alertHandler("danger", e)
    }
    this.setState({loading: false})
  }

  /**
   * Creates a new room with the data from the modal and uploads an image if one was selected
   */
  async createRoom() {
    try {
      let res = await doPutJson("/rooms/room/create", this.state.modalData)
      this.setState({...this.state, modalData: {...this.state.modalData, id: res.data}})

      // if user uploaded an image, upload it
      if (this.state.modalData.image) {
        let formData = new FormData()
        formData.append("image", this.state.modalData.image)
        await doPostFile("/rooms/room/" + res.data + "/image", formData)
      }

      this.alertHandler("success", {response: {status: res.status}})

      // clear modalData
      this.setState({
        modalData: {
          ...this.state.modalData,
          id: "",
          building: {id: ""},
          name: "",
          roomNumber: "",
          description: "",
          image: "",
          location: {lat: 0, lng: 0}
        }
      })
    } catch (e) {
      super.alertHandler("danger", e)
    }

    // refresh rooms
    await this.getData("rooms")
  }

  render() {
    return (
      <>
        <Modal
          show={this.state.modal}
          onShow={async () => await this.getData("buildings")}
          onHide={() => this.setState({modal: false})}
          bg={"dark"} text={"white"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{counterpart.translate("rooms.modal.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="newRoomForm.ControlInput1">
                <Form.Label>
                  {counterpart.translate("rooms.modal.buildings")}
                </Form.Label>
                <Form.Select aria-label="building" onChange={(e) => {
                  this.setState({modalData: {...this.state.modalData, building: {id: e.target.value}}})
                }}
                >
                  <option value="">-- {counterpart.translate("rooms.modal.selectBuilding")} --</option>
                  {this.state.buildings.map((building) => {
                    return <option value={building.id} key={building.id}>
                      {building.name}
                    </option>
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="newRoomForm.ControlInput1">
                <Form.Label>{counterpart.translate("rooms.modal.name")}</Form.Label>
                <Form.Control
                  placeholder={counterpart.translate("rooms.modal.namePlaceholder")}
                  value={this.state.modalData.name}
                  onChange={(e) => {
                    this.setState({modalData: {...this.state.modalData, name: e.target.value}})
                  }}
                  type="text" autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="newRoomForm.ControlInput1">
                <Form.Label>{counterpart.translate("rooms.modal.roomNumber")}</Form.Label>
                <Form.Control
                  value={this.state.modalData.roomNumber}
                  onChange={(e) => {
                    this.setState({modalData: {...this.state.modalData, roomNumber: e.target.value}})
                  }}
                  type="text" placeholder={counterpart.translate("rooms.modal.eG")} autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="newRoomForm.ControlTextarea1">
                <Form.Label>
                  {counterpart.translate("rooms.modal.description")}
                </Form.Label>
                <Form.Control
                  value={this.state.modalData.description}
                  as="textarea"
                  placeholder={counterpart.translate("rooms.modal.descriptionPlaceholder")}
                  rows={3}
                  onChange={(e) => {
                    this.setState({modalData: {...this.state.modalData, description: e.target.value}})
                  }}
                />
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>{counterpart.translate("rooms.modal.selectImage")}</Form.Label>
                <Form.Control
                  type="file" onChange={(e) => {
                  this.setState({modalData: {...this.state.modalData, image: e.target.files[0]}})
                }}/>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({modal: false})
                this.createRoom()
              }}
            >
              {counterpart.translate("rooms.modal.btn.save")}
            </Button>
            <Button variant="primary" onClick={() => this.setState({modal: false})}>
              {counterpart.translate("rooms.modal.btn.cancel")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="text-center" bg={"dark"} text={"white"}>
          <Card.Body>
            <Card.Text>{counterpart.translate("rooms.note")}</Card.Text>
            <Button variant="primary" onClick={() => this.setState({modal: true})}
                    disabled={this.state.rooms.length === 0}>
              {counterpart.translate("rooms.btn.createRoom")}
            </Button>
          </Card.Body>
        </Card>
        <Row xs={1} sm={2} md={3} lg={4} className="g-4 mt-1">
          {this.state.loading && (<Spinner animation="border" role="status" className="m-auto p-5 my-5">
            <span className="visually-hidden">{counterpart.translate("rooms.loading")}</span>
          </Spinner>)}
          {this.state.rooms.sort().map((room) =>
            <Col key={room.id}><Room room={room}/></Col>
          )}
        </Row>
      </>);
  }
}

export default Rooms;
