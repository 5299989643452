import {useEffect} from "react";
import {handleSigninCallback, handleSigninSilentCallback, handleSignoutCallback} from "./auth";

const AuthCallback = ({signout, silent}) => {
  console.log("AuthCallback")
  useEffect(() => {
    if (signout) {
      handleSignoutCallback()
    } else {
      if (silent) {
        handleSigninSilentCallback()
      } else {
        handleSigninCallback()
      }
    }
  }, [])
}

export default AuthCallback