import {useState, useEffect} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AuthCallback from "./etc/scripts/authCallback";
import Rooms from "./Screens/Rooms";
import Profile from "./Screens/Profile";
import NavBar from "./Components/NavBar";
import Room from "./Screens/Room";
import "bootstrap/dist/css/bootstrap.min.css";
import {Col, Container, Row} from "react-bootstrap";

import counterpart from "counterpart";
import de from "./etc/lang/de";
import en from "./etc/lang/en";
import ru from "./etc/lang/ru";
import uk from "./etc/lang/uk";
import pl from "./etc/lang/pl";
import GlobalAlert from "./Components/GlobalAlert";

function registerTranslations() {
  counterpart.registerTranslations("de", de);
  counterpart.registerTranslations("en", en);
  counterpart.registerTranslations("ru", ru);
  counterpart.registerTranslations("uk", uk);
  counterpart.registerTranslations("pl", pl);
}

function App() {
  const [state, setState] = useState({
    site: "canteen",
    alertState: {show: false, type: "danger", event: null},
  });

  useEffect(() => {
    registerTranslations();
    const userLang = navigator.language.substring(0, 2);
    counterpart.setLocale(userLang);
    counterpart.setFallbackLocale("de");
  }, []);

  function showAlert(type, event) {
    setState({
      ...state,
      alertState: {...state.alertState, show: true, type, event},
    });
  }

  function handleAlertClose() {
    setState({...state, alertState: {...state.alertState, show: false}});
  }

  function siteHandler(site) {
    setState({...state, site});
  }

  return (
    <>
      <style type="text/css">{'body {background: #0e0e0f !important}'}</style>
      <BrowserRouter location="/">
        <NavBar currentSite={state.site} siteHandler={siteHandler}/>
        <GlobalAlert alertState={state.alertState} onEvent={showAlert} onClose={handleAlertClose}/>

        <Container>
          <Row className="mt-4">
            <Col>
              <Routes>
                <Route exact path="/rooms/room" element={<Room onEvent={showAlert}/>}/>
                <Route exact path="/rooms" element={<Rooms onEvent={showAlert}/>}/>
                <Route exact path="/profile" element={<Profile onEvent={showAlert}/>}/>
                <Route exact path="/signin-callback" element={<AuthCallback/>}/>
                <Route exact path="/signin-silent-callback" element={<AuthCallback silent/>}/>
                <Route exact path="/signout-callback" element={<AuthCallback signout/>}/>
                <Route path="*" element={<Rooms onEvent={showAlert}/>}/>
              </Routes>
            </Col>
          </Row>
        </Container>
      </BrowserRouter>
    </>
  );
}

export default App;