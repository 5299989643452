import {Alert, Col, Container, Row} from "react-bootstrap";
import {Component} from "react";
import counterpart from "counterpart";

export default class GlobalAlert extends Component {
  constructor(props) {
    super(props);
    this.alertHandler = this.alertHandler.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  /**
   * displays an alert with the given type and text
   * @param type "danger", "warning", "success", "info"
   * @param event the event which contains the error message, status code, etc.
   */
  alertHandler(type, event) {
    this.props.onEvent(type, event)
  }

  onClose() {
    this.props.onClose(); // closes the alert
  }

  render() {
    let alert = this.props.alertState;
    return (
      <Container>
        <Row>
          <Col>
            <Alert variant={alert.type} onClose={this.onClose} show={alert.show} dismissible className="m-0 mt-4">
              {counterpart(`alerts.${alert.event?.response?.status}`)}
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }
}