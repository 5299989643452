const prod = {
  API_URL: 'https://api.stupsin.sneekdev.com',
  KEYCLOAK_URL: 'https://keycloak.stupsin.sneekdev.com'
}

const dev = {
  API_URL: 'http://192.168.0.58:8081',
  KEYCLOAK_URL: 'http://192.168.0.58:8181'
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod