import {doPostJson} from "./requests";
import {config} from "./constants";

export class PushClient {
  constructor(stateChangeCb, subscriptionUpdate, publicAppKey) {
    this._stateChangeCb = stateChangeCb;
    this._subscriptionUpdate = subscriptionUpdate;

    this._publicApplicationKey = base64UrlToUint8Array(publicAppKey);

    this._state = {
      UNSUPPORTED: {
        id: 'UNSUPPORTED',
        interactive: false,
        pushEnabled: false,
      },
      INITIALISING: {
        id: 'INITIALISING',
        interactive: false,
        pushEnabled: false,
      },
      PERMISSION_DENIED: {
        id: 'PERMISSION_DENIED',
        interactive: false,
        pushEnabled: false,
      },
      PERMISSION_GRANTED: {
        id: 'PERMISSION_GRANTED',
        interactive: true,
      },
      PERMISSION_PROMPT: {
        id: 'PERMISSION_PROMPT',
        interactive: true,
        pushEnabled: false,
      },
      ERROR: {
        id: 'ERROR',
        interactive: false,
        pushEnabled: false,
      },
      STARTING_SUBSCRIBE: {
        id: 'STARTING_SUBSCRIBE',
        interactive: false,
        pushEnabled: true,
      },
      SUBSCRIBED: {
        id: 'SUBSCRIBED',
        interactive: true,
        pushEnabled: true,
      },
      STARTING_UNSUBSCRIBE: {
        id: 'STARTING_UNSUBSCRIBE',
        interactive: false,
        pushEnabled: false,
      },
      UNSUBSCRIBED: {
        id: 'UNSUBSCRIBED',
        interactive: true,
        pushEnabled: false,
      },
    };

    if (!('serviceWorker' in navigator)) {
      this._stateChangeCb(this._state.UNSUPPORTED, 'Service worker not ' +
        'available on this browser');
      return;
    }

    if (!('PushManager' in window)) {
      this._stateChangeCb(this._state.UNSUPPORTED, 'PushManager not ' +
        'available on this browser');
      return;
    }

    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      this._stateChangeCb(this._state.UNSUPPORTED, 'Showing Notifications ' +
        'from a service worker is not available on this browser');
      return;
    }

    this.init();
  }

  async init() {
    await navigator.serviceWorker.ready;
    this._stateChangeCb(this._state.INITIALISING);
    this.setUpPushPermission();
  }

  _permissionStateChange(permissionState) {
    // If the notification permission is denied, it's a permanent block
    switch (permissionState) {
      case 'denied':
        this._stateChangeCb(this._state.PERMISSION_DENIED);
        break;
      case 'granted':
        this._stateChangeCb(this._state.PERMISSION_GRANTED);
        break;
      case 'default':
        this._stateChangeCb(this._state.PERMISSION_PROMPT);
        break;
      default:
        console.error('Unexpected permission state: ', permissionState);
        break;
    }
  }

  async setUpPushPermission() {
    try {
      this._permissionStateChange(Notification.permission);

      const reg = await navigator.serviceWorker.ready;
      // Let's see if we have a subscription already
      const subscription = await reg.pushManager.getSubscription();

      // Update the current state with the
      // subscriptionid and endpoint
      this._subscriptionUpdate(subscription);
      if (!subscription) {
        // NOOP since we have no subscription and the permission state
        // will inform whether to enable or disable the push UI
        return;
      }

      this._stateChangeCb(this._state.SUBSCRIBED);
    } catch (err) {
      console.error('setUpPushPermission() ', err);
      this._stateChangeCb(this._state.ERROR, err);
    }
  }

  async subscribeDevice() {
    this._stateChangeCb(this._state.STARTING_SUBSCRIBE);

    try {
      switch (Notification.permission) {
        case 'denied':
          throw new Error('Push messages are blocked.');
        case 'granted':
          break;
        default:
          await new Promise((resolve, reject) => {
            Notification.requestPermission((result) => {
              if (result !== 'granted') {
                reject(new Error('Bad permission result'));
              }
              resolve();
            });
          });
      }

      // We need the service worker registration to access the push manager
      try {
        const reg = await navigator.serviceWorker.ready;
        const subscription = await reg.pushManager.subscribe(
          {
            userVisibleOnly: true,
            applicationServerKey: this._publicApplicationKey,
          },
        );

        // log the subscription details to human readable format
        console.log(JSON.stringify(subscription));

        // send the subscription details to the server
        doPostJson(`${config.API_URL}/notifications/subscribe`, subscription);

        this._stateChangeCb(this._state.SUBSCRIBED);
        this._subscriptionUpdate(subscription);
      } catch (err) {
        this._stateChangeCb(this._state.ERROR, err);
      }
    } catch (err) {
      console.error('subscribeDevice() ', err);
      // Check for a permission prompt issue
      this._permissionStateChange(Notification.permission);
    }
  }

  async unsubscribeDevice() {
    // Disable the switch so it can't be changed while
    // we process permissions
    // window.PushDemo.ui.setPushSwitchDisabled(true);

    this._stateChangeCb(this._state.STARTING_UNSUBSCRIBE);

    try {
      const reg = await navigator.serviceWorker.ready;
      const subscription = await reg.pushManager.getSubscription();

      // log the subscription details to human readable format
      console.log(JSON.stringify(subscription));

      // send the subscription details to the server
      doPostJson('/notifications/unsubscribe', subscription);

      // Check we have everything we need to unsubscribe
      if (!subscription) {
        this._stateChangeCb(this._state.UNSUBSCRIBED);
        this._subscriptionUpdate(null);
        return;
      }

      // You should remove the device details from the server
      // i.e. the  pushSubscription.endpoint
      const successful = await subscription.unsubscribe();
      if (!successful) {
        // The unsubscribe was unsuccessful, but we can
        // remove the subscriptionId from our server
        // and notifications will stop
        // This just may be in a bad state when the user returns
        console.warn('We were unable to unregister from push');
      }

      this._stateChangeCb(this._state.UNSUBSCRIBED);
      this._subscriptionUpdate(null);
    } catch (err) {
      console.error('Error thrown while revoking push notifications. ' +
        'Most likely because push was never registered', err);
    }
  }
}

export function uint8ArrayToBase64Url(uint8Array, start, end) {
  start = start || 0;
  end = end || uint8Array.byteLength;

  const base64 = window.btoa(
    String.fromCharCode.apply(null, uint8Array.subarray(start, end)));
  return base64
    .replace(/\=/g, '') // eslint-disable-line no-useless-escape
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

// Converts the URL-safe base64 encoded |base64UrlData| to an Uint8Array buffer.
export function base64UrlToUint8Array(base64UrlData) {
  const padding = '='.repeat((4 - base64UrlData.length % 4) % 4);
  const base64 = (base64UrlData + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const buffer = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    buffer[i] = rawData.charCodeAt(i);
  }
  return buffer;
}

// Super inefficient. But easier to follow than allocating the
// array with the correct size and position values in that array
// as required.
export function joinUint8Arrays(allUint8Arrays) {
  return allUint8Arrays.reduce(function (cumulativeValue, nextValue) {
    if (!(nextValue instanceof Uint8Array)) {
      throw new Error('Received an non-Uint8Array value.');
    }

    const joinedArray = new Uint8Array(
      cumulativeValue.byteLength + nextValue.byteLength,
    );
    joinedArray.set(cumulativeValue, 0);
    joinedArray.set(nextValue, cumulativeValue.byteLength);
    return joinedArray;
  }, new Uint8Array());
}

export async function arrayBuffersToCryptoKeys(publicKey, privateKey) {
  // Length, in bytes, of a P-256 field element. Expected format of the private
  // key.
  const PRIVATE_KEY_BYTES = 32;

  // Length, in bytes, of a P-256 public key in uncompressed EC form per SEC
  // 2.3.3. This sequence must start with 0x04. Expected format of the
  // public key.
  const PUBLIC_KEY_BYTES = 65;

  if (publicKey.byteLength !== PUBLIC_KEY_BYTES) {
    throw new Error('The publicKey is expected to be ' +
      PUBLIC_KEY_BYTES + ' bytes.');
  }

  // Cast ArrayBuffer to Uint8Array
  const publicBuffer = new Uint8Array(publicKey);
  if (publicBuffer[0] !== 0x04) {
    throw new Error('The publicKey is expected to start with an ' +
      '0x04 byte.');
  }

  const jwk = {
    kty: 'EC',
    crv: 'P-256',
    x: uint8ArrayToBase64Url(publicBuffer, 1, 33),
    y: uint8ArrayToBase64Url(publicBuffer, 33, 65),
    ext: true,
  };

  const keyPromises = [];
  keyPromises.push(crypto.subtle.importKey('jwk', jwk,
    {name: 'ECDH', namedCurve: 'P-256'}, true, []));

  if (privateKey) {
    if (privateKey.byteLength !== PRIVATE_KEY_BYTES) {
      throw new Error('The privateKey is expected to be ' +
        PRIVATE_KEY_BYTES + ' bytes.');
    }

    // d must be defined after the importKey call for public
    jwk.d = uint8ArrayToBase64Url(privateKey);
    keyPromises.push(crypto.subtle.importKey('jwk', jwk,
      {name: 'ECDH', namedCurve: 'P-256'}, true, ['deriveBits']));
  }

  const keys = await Promise.all(keyPromises);

  const keyPair = {
    publicKey: keys[0],
  };
  if (keys.length > 1) {
    keyPair.privateKey = keys[1];
  }
  return keyPair;
}

export async function cryptoKeysToUint8Array(publicKey, privateKey) {
  const promises = [];
  const jwk = await crypto.subtle.exportKey('jwk', publicKey);
  const x = base64UrlToUint8Array(jwk.x);
  const y = base64UrlToUint8Array(jwk.y);

  const pubJwk = new Uint8Array(65);
  pubJwk.set([0x04], 0);
  pubJwk.set(x, 1);
  pubJwk.set(y, 33);

  promises.push(pubJwk);

  if (privateKey) {
    const jwk = await crypto.subtle.exportKey('jwk', privateKey);
    promises.push(
      base64UrlToUint8Array(jwk.d),
    );
  }

  const exportedKeys = await Promise.all(promises);

  const result = {
    publicKey: exportedKeys[0],
  };

  if (exportedKeys.length > 1) {
    result.privateKey = exportedKeys[1];
  }

  return result;
}

export function generateSalt() {
  const SALT_BYTES = 16;
  return crypto.getRandomValues(new Uint8Array(SALT_BYTES));
}

export default class Notifications {
  constructor(publicKey) {
    this._stateChangeListener = this._stateChangeListener.bind(this);
    this._subscriptionUpdate = this._subscriptionUpdate.bind(this);

    this._pushClient = new PushClient(
      this._stateChangeListener,
      this._subscriptionUpdate,
      publicKey
    );

    this._toggleSwitch = getElement('.js-enable-checkbox');
    this._toggleSwitch.addEventListener('click', () => this.togglePush());
  }

  togglePush() {
    (this._toggleSwitch.checked) ?
      this._pushClient.subscribeDevice() :
      this._pushClient.unsubscribeDevice();
  }

  registerServiceWorker() {
    // Check that service workers are supported
    if ('serviceWorker' in navigator) {
      console.log('Service Worker is supported');
      navigator.serviceWorker.register('./service-worker.js')
        .catch((err) => {
          console.error(err);
          console.error(
            'Unable to Register SW',
            'Sorry this demo requires a service worker to work and it ' +
            'failed to install - sorry :(',
          );
        });
    } else {
      console.error(
        'Service Worker Not Supported',
        'Sorry this demo requires service worker support in your browser. ' +
        'Please try this demo in Chrome or Firefox Nightly.',
      );
    }
  }

  _stateChangeListener(state, data) {
    if (typeof state.interactive !== 'undefined') {
      if (state.interactive) {
        this._toggleSwitch.disabled = false;
      } else {
        this._toggleSwitch.disabled = true;
      }
    }

    if (typeof state.pushEnabled !== 'undefined') {
      if (state.pushEnabled) {
        this._toggleSwitch.checked = true;
      } else {
        this._toggleSwitch.checked = false;
      }
    }

    switch (state.id) {
      case 'UNSUPPORTED':
        console.error(
          'Push Not Supported',
          data,
        );
        break;
      case 'ERROR':
        console.error(
          'Ooops a Problem Occurred',
          data,
        );
        break;
      default:
        break;
    }
  }

  _subscriptionUpdate(subscription) {
    this._currentSubscription = subscription;

    // This is too handle old versions of Firefox where keys would exist
    // but auth wouldn't
    const subscriptionObject = JSON.parse(JSON.stringify(subscription));
    if (
      subscriptionObject &&
      subscriptionObject.keys &&
      subscriptionObject.keys.auth &&
      subscriptionObject.keys.p256dh) {
    }
  }

  toBase64(arrayBuffer, start, end) {
    start = start || 0;
    end = end || arrayBuffer.byteLength;

    const partialBuffer = new Uint8Array(arrayBuffer.slice(start, end));
    return window.btoa(String.fromCharCode.apply(null, partialBuffer));
  }

  toHex(arrayBuffer) {
    return [...new Uint8Array(arrayBuffer)]
      .map((x) => x.toString(16).padStart(2, '0'))
      .join(' ');
  }
}

// This is a helper method so we get an error and log in case we delete or
// rename an element we expect to be in the DOM.
function getElement(selector) {
  const e = document.querySelector(selector);
  if (!e) {
    console.error(`Failed to find element: '${selector}'`);
    throw new Error(`Failed to find element: '${selector}'`);
  }
  return e;
}