/**
 * @ author Jakob Frömbgen
 * описание: языковой файл, язык: Русский
 */
const ru = {

  /**
   * структура:
   *
   * <screenName> : {
   *   <element>: <translation>
   *   <buttons>: {
   *     <button_name>: <translation>
   *   }
   * }
   */

  navBar: {
    canteen: "Столовая",
    rooms: "Комнаты",
    chess: "Шахматы",
    btn: {
      login: "Войти",
      logout: "Выйти",
      profile: "Профиль",
    }
  },

  profile: {
    language: "Язык",
    btn: {
      deleteProfile: "Удалить профиль",
      darkTheme: "Темный режим",
      notifications: "Уведомления"
    }
  },

  rooms: {
    note: "Если вы не можете найти свою комнату, вы можете создать ее здесь.",
    loading: "Загрузка комнат...",
    modal: {
      name: "Название",
      title: "Создать комнату",
      buildings: "Здания",
      selectBuilding: "Выберите здание",
      roomNumber: "Номер комнаты",
      namePlaceholder: "Комната пыток",
      eG: "например: 4.064",
      descriptionPlaceholder: "Здесь происходят пытки",
      description: "Описание",
      selectImage: "Выбрать изображение",
      btn: {
        save: "Сохранить",
        cancel: "Отмена",
      }
    },
    btn: {
      createRoom: "Создать комнату",
    },
  },

  room: {
    modal: {
      title: "Присоединиться к комнате",
      btn: {
        close: "закрыть"
      }
    },
    btn: {
      delete: "Удалить комнату",
      generateQRCode: "Сгенерировать QR-код"
    },
    nobody: "Никто",
    personSg: "Человек",
    personPl: "Люди",
    personGenPl: "Людей",
    inTheRoom: "в комнате",
  },

  alerts: {
    200: "Запрос был успешно выполнен",
    401: "У вас нет прав для выполнения этого действия",
    403: "У вас нет прав для выполнения этого действия",
    404: "Запрашиваемая страница не найдена",
    500: "На сервере произошла неожиданная ошибка",
    503: "Сервер в настоящее время недоступен",
  },

  error: {
    ERR_SERVER: "На сервере произошла неожиданная ошибка.",
    ERR_INVALID_REQUEST: "Неправильный запрос.",
    ERR_NO_INPUT: "Вы ничего не ввели.",
    ERR_SAVE_DB_ERROR: "Произошла ошибка при сохранении.",
    ERR_WRONG_CREDENTIALS: "Введенный адрес электронной почты или пароль неверны.",
    ERR_INVALID_EMAIL: "Пожалуйста, введите действительный адрес электронной почты.",
    ERR_EMAIL_TOO_LONG: "Ваш адрес электронной почты может содержать не более 256 символов.",
    ERR_PASSWORDS_NOT_IDENTICAL: "Пароли не совпадают.",
    ERR_NAME_EXISTS: "Это имя уже существует.",
    ERR_EMAIL_EXISTS: "Этот адрес электронной почты уже существует.",
    ERR_404: "Запрашиваемая страница не найдена.",
    ERR_TOKEN_EXPIRED: "Указанный токен истек.",
    ERR_TOKEN_EMAIL_INVALID: "Введенный адрес электронной почты не существует или указанный токен недействителен.",
    ERR_NO_PASSWORD_CONFIRMATION: "Пожалуйста, введите текущий пароль, чтобы внести изменения.",
    ERR_ACCOUNT_NOT_VERIFIED: "Пожалуйста, подтвердите свою учетную запись с помощью ссылки подтверждения, отправленной вам.",
    ERR_IMAGE_UPLOAD: "При загрузке изображения произошла ошибка. Файл не должен превышать 5 МБ. Допустимые форматы файлов: GIF, PNG и JPEG."
  },
};

export default ru;
