import {Log, UserManager} from 'oidc-client-ts';
import {config} from './constants';

// get current address from url
const currentAddress = window.location.hostname;
let keycloakAddress = config.KEYCLOAK_URL;
console.log("keycloakAddress: " + keycloakAddress)

const settings = {
  authority: `${keycloakAddress}/realms/stupsIn/`,
  client_id: "stupsIn",
  response_type: 'code',
  response_mode: 'query',
  scope: "openid",
  automaticSilentRenew: true,
  silent_redirect_uri: `http://${currentAddress}:3000/signin-silent-callback`
}

Log.setLogger(console);
Log.setLevel(Log.ERROR);

export const userManager = new UserManager(settings)

export const getUser = () => {
  return userManager.getUser()
}

export const login = () => {
  return userManager.signinRedirect({redirect_uri: `http://${currentAddress}:3000/signin-callback?location=${window.location.href}`})
}

export const logout = () => {
  return userManager.signoutRedirect({post_logout_redirect_uri: `http://${currentAddress}:3000/signout-callback`})
}

export const handleSigninCallback = () => {
  console.log("handleSigninCallback")
  userManager.signinRedirectCallback().then(user => {
    const params = new URLSearchParams(window.location.search);
    console.log("logged in ")
    window.location = params.get('location')
  }).catch(e => {
    console.error(e)
    const params = new URLSearchParams(window.location.search);
    window.location = params.get('location')
  });
}

export const handleSigninSilentCallback = () => {
  userManager.signinSilentCallback().then(user => {
    console.log("silently logged in ")
  }).catch(e => {
    console.log("failed to silently logged in ")
    console.error(e)
  });
}

export const handleSignoutCallback = () => {
  userManager.signoutCallback().then(() => {
    console.log("logged out ")
    window.location = '/'
  }).catch(e => {
    console.error(e)
    window.location = '/'
  });
}
