import CustomComponent from "./CustomComponent";

export default class CardBgImage extends CustomComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let img = (this.props.images && this.props.images.length !== 0) ? this.props.images[0].id : "";
    let style = {
      backgroundColor: "#151515",
      height: this.props.height,
      backgroundImage: `url("${this.gateway}/rooms/room/${this.props.imgType}/${img}")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderRadius: "0.375rem",
      borderBottomRightRadius: this.props.noBottomBorder ? 0 : "0.375rem",
      borderBottomLeftRadius: this.props.noBottomBorder ? 0 : "0.375rem",
    }
    return <div style={style}></div>
  }
}