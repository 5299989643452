import axios from 'axios';
import {getUser, userManager} from "./auth";

export const httpClient = axios.create({
  timeout: 20000
});

httpClient.interceptors.request.use(
  config => {
    return getUser().then(user => {
      if (user && user.access_token) {
        config.headers['Authorization'] = 'Bearer ' + user.access_token
      }
      return Promise.resolve(config)
    })
  }, error => {
    console.log("error: ")
    Promise.reject(error)
  }
);

export function doRequest(config) {
  config.withCredentials = false;
  return httpClient.request(config)
}

export function doGetJson(path) {
  return doRequest({url: path, method: 'get'});
}

export function doPutJson(path, obj) {
  return doRequest({
    url: path,
    method: 'put',
    headers: {'Content-Type': 'application/json'},
    data: JSON.stringify(obj)
  });
}

export function doPostFile(path, obj) {
  return doRequest({
    url: path,
    method: 'post',
    headers: {'Content-Type': 'multipart/form-data'},
    data: obj
  });
}


export function doPutText(path, text) {
  return doRequest({
    url: path,
    method: 'put',
    headers: {'Content-Type': 'text/plain'},
    data: text
  });
}

export function doPostJson(path, obj) {
  return doRequest({
    url: path,
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    data: JSON.stringify(obj)
  });
}

export function doPost(path) {
  return doRequest({
    url: path,
    method: 'post',
  });
}

export function doDelete(path) {
  return doRequest({
    url: path,
    method: 'delete'
  });
}

