/**
 * @ author Jakob Frömbgen
 * opis: plik językowy, język: polski
 */
const pl = {

  /**
   * struktura:
   *
   * <screenName> : {
   *   <element>: <translation>
   *   <buttons>: {
   *     <button_name>: <translation>
   *   }
   * }
   */

  navBar: {
    canteen: "Stołówka",
    rooms: "Pokoje",
    chess: "Szachy",
    btn: {
      login: "Zaloguj się",
      logout: "Wyloguj się",
      profile: "Profil",
    }
  },

  profile: {
    language: "Język",
    btn: {
      deleteProfile: "Usuń profil",
      darkTheme: "Ciemny motyw",
      notifications: "Powiadomienia"
    }
  },

  rooms: {
    note: "Jeśli nie możesz znaleźć swojego pokoju, możesz go tutaj utworzyć.",
    loading: "Wczytywanie pokoi...",
    modal: {
      name: "Nazwa",
      title: "Utwórz pokój",
      buildings: "Budynki",
      selectBuilding: "Wybierz budynek",
      roomNumber: "Numer pokoju",
      namePlaceholder: "Komnata tortur",
      eG: "np.: 4.064",
      descriptionPlaceholder: "Tutaj odbywają się tortury",
      description: "Opis",
      selectImage: "Wybierz obraz",
      btn: {
        save: "Zapisz",
        cancel: "Anuluj",
      }
    },
    btn: {
      createRoom: "Utwórz pokój",
    },
  },

  room: {
    modal: {
      title: "Dołącz do pokoju",
      btn: {
        close: "zamknij"
      }
    },
    btn: {
      delete: "Usuń pokój",
      generateQRCode: "Wygeneruj kod QR"
    },
    nobody: "Nikt",
    personSg: "Osoba",
    personPl: "Ludzie",
    personGenPl: "Osób",
    inTheRoom: "w pokoju",
  },

  alerts: {
    200: "Żądanie zostało pomyślnie wykonane",
    401: "Nie masz uprawnień do wykonania tej czynności",
    403: "Nie masz uprawnień do wykonania tej czynności",
    404: "Żądana strona nie została znaleziona",
    500: "Na serwerze wystąpił nieoczekiwany błąd",
    503: "Serwer jest chwilowo niedostępny",
  },

  error: {
    ERR_SERVER: "Na serwerze wystąpił nieoczekiwany błąd.",
    ERR_INVALID_REQUEST: "Nieprawidłowe żądanie.",
    ERR_NO_INPUT: "Nic nie wprowadziłeś.",
    ERR_SAVE_DB_ERROR: "Wystąpił błąd podczas zapisywania.",
    ERR_WRONG_CREDENTIALS: "Podany adres e-mail lub hasło jest nieprawidłowy.",
    ERR_INVALID_EMAIL: "Proszę podać prawidłowy adres e-mail.",
    ERR_EMAIL_TOO_LONG: "Twój adres e-mail może zawierać maksymalnie 256 znaków.",
    ERR_PASSWORDS_NOT_IDENTICAL: "Twoje hasła nie pasują do siebie.",
    ERR_NAME_EXISTS: "Ta nazwa już istnieje.",
    ERR_EMAIL_EXISTS: "Ten adres e-mail już istnieje.",
    ERR_404: "Żądana strona nie została znaleziona.",
    ERR_TOKEN_EXPIRED: "Podany token wygasł.",
    ERR_TOKEN_EMAIL_INVALID: "Podany adres e-mail nie istnieje lub podany token jest nieprawidłowy.",
    ERR_NO_PASSWORD_CONFIRMATION: "Proszę podać aktualne hasło, aby dokonać zmian.",
    ERR_ACCOUNT_NOT_VERIFIED: "Potwierdź swoje konto za pomocą linku potwierdzającego wysłanego do Ciebie.",
    ERR_IMAGE_UPLOAD: "Wystąpił błąd podczas przesyłania obrazu. Plik nie może przekraczać 5 MB. Dozwolone formaty plików: GIF, PNG i JPEG."
  },
};

export default pl;
