/**
 * @author Jakob Frömbgen
 * description: language file, language: Deutsch
 */
const de = {

  /**
   * structure:
   *
   * <screenName> : {
   *   <element>: <translation>
   *   <buttons>: {
   *     <button_name>: <translation>
   *   }
   * }
   */

  navBar: {
    canteen: "Mensa",
    rooms: "Räume",
    chess: "Schach",
    btn: {
      login: "Anmelden",
      logout: "Abmelden",
      profile: "Profil",
    }
  },

  profile: {
    language: "Sprache",
    btn: {
      deleteProfile: "Profil löschen",
      darkTheme: "Dunkler Modus",
      notifications: "Benachrichtigungen"
    }
  },

  rooms: {
    note: "Wenn du deinen Raum nicht findest, kannst du ihn hier erstellen.",
    loading: "Lade Räume...",
    modal: {
      name: "Name",
      title: "Raum erstellen",
      buildings: "Gebäude",
      selectBuilding: "Gebäude auswählen",
      roomNumber: "Raumnummer",
      namePlaceholder: "Folterkammer",
      eG: "z.B.: 4.064",
      descriptionPlaceholder: "Hier wird gefoltert",
      description: "Beschreibung",
      selectImage: "Bild auswählen",
      btn: {
        save: "Speichern",
        cancel: "Abbrechen",
      }
    },
    btn: {
      createRoom: "Raum erstellen",
    },
  },

  room: {
    modal: {
      title: "Raum beitreten",
      btn: {
        close: "schließen"
      }
    },
    btn: {
      delete: "Raum löschen",
      generateQRCode: "QR Code generieren"
    },
    nobody: "Keine",
    personSg: "Person",
    personPl: "Personen",
    personGenPl: "Personen",
    inTheRoom: "im Raum",
  },

  alerts: {
    200: "Die Anfrage wurde erfolgreich ausgeführt",
    401: "Du bist nicht berechtigt, diese Aktion auszuführen",
    403: "Du bist nicht berechtigt, diese Aktion auszuführen",
    404: "Die angeforderte Seite wurde nicht gefunden",
    500: "Auf dem Server ist ein unerwarteter Fehler aufgetreten",
    503: "Der Server ist zurzeit nicht erreichbar",
  },

  error: {
    ERR_SERVER: "Auf dem Server ist ein unerwarteter Fehler aufgetreten.",
    ERR_INVALID_REQUEST: "Falsche Anfrage.",
    ERR_NO_INPUT: "Du hast nichts eingegeben.",
    ERR_SAVE_DB_ERROR: "Beim Abspeichern ist ein Fehler aufgetreten.",
    ERR_WRONG_CREDENTIALS: "Die eingegebene E-Mail oder das Passwort sind falsch.",
    ERR_INVALID_EMAIL: "Bitte gebe eine richtige E-Mail Adresse an.",
    ERR_EMAIL_TOO_LONG: "Deine E-Mail Adresse darf maximal 256 Zeichen lang sein.",
    ERR_PASSWORDS_NOT_IDENTICAL: "Deine Passwörter stimmen nicht überein.",
    ERR_NAME_EXISTS: "Dieser Name existiert bereits.",
    ERR_EMAIL_EXISTS: "Diese E-Mail Adresse existiert bereits.",
    ERR_404: "Die angeforderte Seite wurde nicht gefunden.",
    ERR_TOKEN_EXPIRED: "Der gegebene Token ist abgelaufen.",
    ERR_TOKEN_EMAIL_INVALID: "Die eingegebene E-Mail existiert nicht oder der gegebene Token ist ungültig.",
    ERR_NO_PASSWORD_CONFIRMATION: "Bitte gib dein aktuelles Passwort ein, um Änderungen vorzunehmen.",
    ERR_ACCOUNT_NOT_VERIFIED: "Bitte bestätige Deinen Account mit dem Dir zugesandten Bestätigungslink.",
    ERR_IMAGE_UPLOAD: "Beim Hochladen des Bildes ist ein Fehler aufgetreten. Die Datei darf maximal 5MB groß sein. Zugelassene Dateiformate: GIF, PNG und JPEG."
  },
};

export default de;
