/**
 * @ author Jakob Frömbgen
 * опис: мовний файл, мова: українська
 */
const uk = {

  /**
   * структура:
   *
   * <screenName> : {
   *   <element>: <translation>
   *   <buttons>: {
   *     <button_name>: <translation>
   *   }
   * }
   */

  navBar: {
    canteen: "Столова",
    rooms: "Кімнати",
    chess: "Шахи",
    btn: {
      login: "Увійти",
      logout: "Вийти",
      profile: "Профіль",
    }
  },

  profile: {
    language: "Мова",
    btn: {
      deleteProfile: "Видалити профіль",
      darkTheme: "Темний режим",
      notifications: "Сповіщення"
    }
  },

  rooms: {
    note: "Якщо ви не можете знайти свою кімнату, ви можете створити її тут.",
    loading: "Завантаження кімнат...",
    modal: {
      name: "Назва",
      title: "Створити кімнату",
      buildings: "Будівлі",
      selectBuilding: "Виберіть будівлю",
      roomNumber: "Номер кімнати",
      namePlaceholder: "Кімната покарань",
      eG: "напр.: 4.064",
      descriptionPlaceholder: "Тут проводяться покарання",
      description: "Опис",
      selectImage: "Вибрати зображення",
      btn: {
        save: "Зберегти",
        cancel: "Скасувати",
      }
    },
    btn: {
      createRoom: "Створити кімнату",
    },
  },

  room: {
    modal: {
      title: "Приєднатися до кімнати",
      btn: {
        close: "закрити"
      }
    },
    btn: {
      delete: "Видалити кімнату",
      generateQRCode: "Створити QR-код"
    },
    nobody: "Ніхто",
    personSg: "Особа",
    personPl: "Люди",
    personGenPl: "Осіб",
    inTheRoom: "у кімнаті",
  },

  alerts: {
    200: "Запит виконано успішно",
    401: "Ви не маєте дозволу на виконання цієї дії",
    403: "Ви не маєте дозволу на виконання цієї дії",
    404: "Запитана сторінка не знайдена",
    500: "На сервері сталася непередбачувана помилка",
    503: "Сервер тимчасово недоступний",
  },

  error: {
    ERR_SERVER: "На сервері сталася непередбачувана помилка.",
    ERR_INVALID_REQUEST: "Неприпустимий запит.",
    ERR_NO_INPUT: "Ви нічого не ввели.",
    ERR_SAVE_DB_ERROR: "Під час збереження виникла помилка.",
    ERR_WRONG_CREDENTIALS: "Введена електронна адреса або пароль неправильні.",
    ERR_INVALID_EMAIL: "Будь ласка, введіть дійсну електронну адресу.",
    ERR_EMAIL_TOO_LONG: "Ваша електронна адреса може містити максимум 256 символів.",
    ERR_PASSWORDS_NOT_IDENTICAL: "Ваші паролі не співпадають.",
    ERR_NAME_EXISTS: "Ця назва вже існує.",
    ERR_EMAIL_EXISTS: "Ця електронна адреса вже існує.",
    ERR_404: "Запитана сторінка не знайдена.",
    ERR_TOKEN_EXPIRED: "Даний токен прострочений.",
    ERR_TOKEN_EMAIL_INVALID: "Введена електронна адреса не існує або даний токен недійсний.",
    ERR_NO_PASSWORD_CONFIRMATION: "Будь ласка, введіть поточний пароль, щоб внести зміни.",
    ERR_ACCOUNT_NOT_VERIFIED: "Будь ласка, підтвердіть свій обліковий запис за допомогою посилання підтвердження, відправленого вам.",
    ERR_IMAGE_UPLOAD: "Під час завантаження зображення сталася помилка. Файл не повинен перевищувати 5 МБ. Дозволені формати файлів: GIF, PNG та JPEG."
  },
};

export default uk;
