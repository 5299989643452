import {Button, Card, Image, Form, InputGroup} from "react-bootstrap";
import avatar from "../etc/img/avatar.png";
import counterpart from 'counterpart';
import {doPostJson} from "../etc/scripts/requests";
// import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import AppController from "../etc/scripts/notifications";
import CustomComponent from "../Components/CustomComponent";

export default class Profile extends CustomComponent {

  componentDidMount() {

    // get the user language from the browser, e.g. "de"
    const userLang = navigator.language.substring(0, 2)
    counterpart.setFallbackLocale('de');
    counterpart.setLocale(userLang)
    this.setState({lang: userLang})

    if (!navigator.serviceWorker) {
      console.warn('Service workers are not supported in this browser.');
      return;
    }

    if (!('PushManager' in window)) {
      console.warn('Push is not supported in this browser.');
      return;
    }

    // post request to the server to get the public key
    doPostJson(`${this.gateway}/notifications/public-key`).then((response) => {
      if (response.status !== 200) {
        console.warn('Unable to get the public key.');
      } else {
        const appController = new AppController(response.data);
        appController.registerServiceWorker();
      }
    });
  };

  state = {
    lang: counterpart.getLocale()
  }

  /**
   * Changes the language
   * @param l the new language
   */
  changeLang(l) {
    this.setState({lang: l})
    counterpart.setLocale(l);
  }

  render() {
    return (
      <>
        <Card bg={"dark"} text={"white"} className="p-5">
          <Image width={100} height={100} src={avatar} roundedCircle className="m-auto bg-black"/>
          <Card.Body className="m-auto" style={{maxWidth: "240px"}}>
            <Card.Title className="text-center">
              Olaf Durban
            </Card.Title>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
              <Form.Control
                placeholder="Hulks1234"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>
                  {counterpart.translate("profile.language")}
                </Form.Label>
                <Form.Select value={this.state.lang} aria-label="Sprache"
                             onChange={(e) => this.changeLang(e.target.value)}>
                  <option value="de">Deutsch 🇩🇪</option>
                  <option value="en">English 🇺🇸</option>
                  <option value="ru">русский язык 🇷🇺</option>
                  <option value="uk">українська мова 🇺🇦</option>
                  <option value="pl">język polski 🇵🇱</option>
                </Form.Select>
              </Form.Group>
              {/*<Form.Group>*/}
              {/*  <Form.Check type="switch" id="theme-switcher" label={counterpart.translate("profile.btn.darkTheme")}/>*/}
              {/*</Form.Group>*/}
              <Form.Group>
                <label className="form-label">
                  {counterpart.translate("profile.btn.notifications")}
                </label>
                <input
                  type="checkbox"
                  id="notification-switcher"
                  className="js-enable-checkbox form-check-input"
                  disabled={true}
                />
              </Form.Group>
              <Form.Group>
                <Button className="w-100" variant="danger" onClick={() => alert("Das geht nicht...")}>
                  {counterpart.translate("profile.btn.deleteProfile")}
                </Button>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </>
    );
  }
}