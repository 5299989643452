import {Component} from "react";
import {config} from "../etc/scripts/constants";

export default class CustomComponent extends Component {
  constructor(props) {
    super(props);
    this.gateway = config.API_URL;
  }

  alertHandler(type, event) {
    this.props.onEvent(type, event)
  }
}