import React from 'react';
import {doDelete, doGetJson, doPostJson} from "../etc/scripts/requests";
import {Button, Card, Image, ListGroup, Modal, Placeholder} from "react-bootstrap";
import avatar from "../etc/img/avatar.png";
import {getUser} from "../etc/scripts/auth";
import CardBgImage from "../Components/CardBgImage";
import QRCode from "react-qr-code";
import counterpart from "counterpart";
import CustomComponent from "../Components/CustomComponent";
import {Link} from "react-router-dom";

export default class Room extends CustomComponent {
  constructor(props) {
    super(props);
    this.roomsApi = this.gateway + "/rooms";
    this.state = {
      user: {},
      room: {users: []},
      isOwner: false
    }
  }

  /**
   * Fetches a room with the given id from the backend
   */
  async getRoom() {
    let res = (await doGetJson(`${this.roomsApi}/room/${this.id}`)).data
    res ? this.setState({room: res}) : alert("Error while fetching rooms")
  }

  /**
   * Adds the current user to the room
   */
  async stupsIn() {
    doPostJson(`${this.roomsApi}/stups_in/${this.id}`).then(() => this.getRoom());
  }

  /**
   * Removes the current user from the room
   */
  async stupsOut() {
    doPostJson(`${this.roomsApi}/stups_out/${this.id}`).then(() => this.getRoom());
  }

  id = new URLSearchParams(window.location.search).get("id")
  url = window.location.href

  async componentDidMount() {
    await this.getRoom();
    getUser().then(user => {
      this.setState({user: user})
    })

    // TODO: &stupsIn=true&stupsIn=true&... -> only one stupsIn=true
    if (new URLSearchParams(window.location.search).get("stupsIn")) {
      await this.stupsIn();
    }
  }

  deleteRoom() {
    doDelete(`${this.roomsApi}/room/${this.id}`).then((response) => {
      if (response.status === 200) {
        window.location.href = "/rooms";
      }
    }).catch((e) => {
      this.alertHandler("danger", e)
    })
  }

  render() {
    return (
      <>
        <Modal
          show={this.state.modal}
          onHide={() => this.setState({modal: false})}
          bg={"dark"} text={"white"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{counterpart.translate("room.modal.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{height: "auto", margin: "0 auto", maxWidth: 256, width: "100%"}}>
              <QRCode
                size={512}
                style={{height: "auto", maxWidth: "100%", width: "100%"}}
                value={this.url + "&stupsIn=true"}
                viewBox={`0 0 256 256`}
              />
              <Link to={this.url + "&stupsIn=true"}>
                {/*{this.url + "&stupsIn=true"}*/}
              </Link>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({modal: false})
                this.createRoom()
              }}
            >
              {counterpart.translate("room.modal.btn.close")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="bg-dark text-white">
          <CardBgImage imgType={"image"} noBottomBorder={false} images={this.state.room.images} height={"120px"}/>
          <Card.ImgOverlay className="text-center">
            <h1 className="fw-bold" style={{textShadow: "1px 1px 1px #aeaeae, 3px 3px 5px #515151"}}>
              {this.state.room.name ||
                <Placeholder as={Card.Title} animation="glow"><Placeholder xs={3} size="lg"/></Placeholder>}
              {this.state.user?.profile?.sub !== this.state.room.owner &&
                <Button variant="danger" onClick={() => this.deleteRoom()}>
                  {counterpart.translate("room.btn.delete")}
                </Button>}
            </h1>
            <Card.Text>
              {(this.state.room.description
                  || <Placeholder as={Card.Title} animation="glow"><Placeholder xs={3} size="lg"/></Placeholder>)
                || counterpart.translate("room.noDescription")}
            </Card.Text>
          </Card.ImgOverlay>
        </Card>

        <Card bg={"dark"} text={"white"} className="mt-4">
          <Card.Body>
            <ListGroup variant="flush">
              {this.state.room.users.length === 0 && <p className={"text-muted text-center my-2"}>
                Keine Stupsis im Raum ¯\_(ツ)_/¯
              </p>}
              {this.state.room.users.map((user) =>
                <ListGroup.Item key={user.id} style={{background: "none"}} className="text-white">
                  <Image width={40} src={avatar} roundedCircle style={{marginRight: "10px"}}/>
                  {user.firstName} {user.lastName}
                </ListGroup.Item>
              )}
            </ListGroup>
          </Card.Body>
          <Card.Footer>
            <Button variant="primary" onClick={() => this.setState({modal: true})}>
              {counterpart.translate("room.btn.generateQRCode")}
            </Button>
            {(this.state.room.users.find(user => user.id === this.state.user.id)) ?
              <Button variant="success" className="m-auto" onClick={() => this.stupsIn()}>
                Stups in
              </Button>
              :
              <Button variant="danger" onClick={() => this.stupsOut()}>
                Stups out
              </Button>
            }
          </Card.Footer>
        </Card>
      </>
    );
  }
}