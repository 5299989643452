import {getUser, login, logout} from "../etc/scripts/auth";
import {Container, Navbar, Button, Nav, NavDropdown, Image} from "react-bootstrap";
import {Component} from "react";
import stupsInLogo from "../etc/img/stups-in-logo-no-bg.svg";
import avatar from "../etc/img/avatar.png";
import counterpart from 'counterpart';
import {Link} from "react-router-dom";

export default class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site: props.site,
      user: null,
      stupsedIn: false
    }
  }

  /**
   * Handles the click on the account button
   */
  handleAccountButtonClick = () => {
    getUser().then(user => {
      user ? logout(window.location.href) : login(window.location.href)
    });
  };

  componentDidMount() {
    getUser().then(user => {
      // if the user is expired, logout, else set the user in the state
      user?.expired ?
        logout(window.location.href):
        this.setState({user: user})
    });

  }

  render() {
    return (
      <Navbar bg="dark" variant="dark" collapseOnSelect expand="lg">
        <Container>
          <div>
            <Image
              src={stupsInLogo}
              width="30"
              height="30"
              alt="Stups logo"
              className="align-top"
              style={{marginRight: 10}}/>
            <Navbar.Brand href="/home">StupsIn</Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/*<Nav.Link*/}
              {/*  as={Link}*/}
              {/*  to="/canteen"*/}
              {/*  onClick={this.props.siteHandler}*/}
              {/*  active={this.props.currentSite === "canteen"}>*/}
              {/*  {counterpart.translate("navBar.canteen")}*/}
              {/*</Nav.Link>*/}
              <Nav.Link
                as={Link}
                to="/rooms"
                onClick={this.props.siteHandler}
                active={this.props.currentSite === "rooms"}>
                {counterpart.translate("navBar.rooms")}
              </Nav.Link>
              {/*<Nav.Link*/}
              {/*  as={Link}*/}
              {/*  to="/chess"*/}
              {/*  onClick={this.props.siteHandler}*/}
              {/*  active={this.props.currentSite === "rooms"}>*/}
              {/*  {counterpart.translate("navBar.chess")}*/}
              {/*</Nav.Link>*/}
            </Nav>
            {this.state.user ?
              <>
                <p className="d-none d-lg-block text-white my-0 mx-2 text-capitalize">
                  {this.state.user.profile.name.split(" ")[0]}
                </p>
                <NavDropdown
                  title={<Image src={avatar} width="30" height="30" roundedCircle/>}
                  id="basic-nav-dropdown">
                  <NavDropdown.Item><Link to="/profile" style={{
                    textDecoration: "none",
                    color: "inherit"
                  }}>{counterpart.translate("navBar.btn.profile")}</Link></NavDropdown.Item>
                  <NavDropdown.Divider/>
                  <NavDropdown.Item onClick={this.handleAccountButtonClick}>
                    {counterpart.translate("navBar.btn.logout")}
                  </NavDropdown.Item>
                </NavDropdown>
              </>
              :
              <Button variant="info" onClick={this.handleAccountButtonClick}>
                {counterpart.translate("navBar.btn.login")}
              </Button>
            }
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}