import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import CardBgImage from "./CardBgImage";
import counterpart from "counterpart";

export default function Room(props) {

  props = props.room;

  return (
    <Link to={"/rooms/room?id=" + props.id} className="text-decoration-none">
      <Card bg={"dark"} text={"white"}>
        <CardBgImage noBottomBorder={true} imgType="thumbnail" images={props.images} height={"120px"}/>
        <Card.Body>
          <Card.Title>{props.name}: (<code>{props.roomNumber}</code>)</Card.Title>
          <Card.Text>
            {props.description}
            <br/>
            <small className="text-muted">
              {(props.users && props.users.length === 0) ? " " + counterpart.translate("room.nobody"): <b>{props.users && props.users.length}</b>}
              {(props.users && props.users.length === 1) ?
                " " + counterpart.translate("room.personSg") + " " :
                " " + counterpart.translate("room.personPl") + " "}
              {counterpart.translate("room.inTheRoom")}
            </small>
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
  );
}